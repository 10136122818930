.snack-bar.mat-mdc-snack-bar-container, .snack-bar {
  white-space: pre-line;

  .mdc-snackbar__surface {
    box-shadow: -1px 3px 20px 1px #212C3726;
    border-radius: 0.5rem;
  }

  &.snack-bar {
    &--success {
      .mdc-snackbar__surface {
        background-color: var(--theme-alert-success-bg, #E6F7F7);
        border: 1px solid var(--theme-alert-success-color, transparent);
      }

      .mdc-snackbar__label {
        color: var(--theme-alert-success-color, #00ABAA);
      }
    }

    &--danger {
      .mdc-snackbar__surface {
        background-color: var(--theme-alert-danger-bg, #FDECF2);
        border: 1px solid var(--theme-alert-danger-color, transparent);
      }

      .mdc-snackbar__label {
        color: var(--theme-alert-danger-color, #E7417A);
      }
    }

    &--warning {
      .mdc-snackbar__surface {
        background-color: var(--theme-alert-warn-bg, #FDECF2);
        border: 1px solid var(--theme-alert-warn-color, transparent);
      }

      .mdc-snackbar__label {
        color: var(--theme-alert-warn-color, #E7417A);
      }
    }

    &--info {
      .mdc-snackbar__surface {
        background-color: var(--theme-alert-info-bg, #F6F6F6);
        border: 1px solid var(--theme-alert-info-color, transparent);
      }

      .mdc-snackbar__label {
        color: var(--theme-alert-info-color, #2D2D2D);
      }
    }

    &--update-message {
      .mdc-snackbar__surface {
        background-color: var(--theme-alert-update-bg, #FFFFFF);
        border: 1px solid var(--theme-alert-update-color, transparent);
      }

      .mdc-snackbar__label {
        color: var(--theme-alert-update-color, #2D2D2D);
      }
    }
  }
}

// Variable overrides
$primary: #00ABAA;
$enable-shadows: true;
$prefix: "";

/* Colors */
/*
$colors: (
  'orange-90': $orange-90,
  'orange-60': $orange-60,
  'grayscale-10' : $grayscale-10,
  'grayscale-20' : $grayscale-20,
  'grayscale-30' : $grayscale-30,
  'grayscale-50' : $grayscale-50,
  'grayscale-70' : $grayscale-70,
  'grayscale-90' : $grayscale-90
);
*/

/* Grid */
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1320px
);
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1280px
);


/* Font-families */
$font-family-base: "Helvetica Neue", sans-serif;


@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeueCyr-Bold.woff2') format('woff2'),
        url('HelveticaNeueCyr-Bold.woff') format('woff'),
        url('HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: url('HelveticaNeueCyr-Roman.woff2') format('woff2'),
        url('HelveticaNeueCyr-Roman.woff') format('woff'),
        url('HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


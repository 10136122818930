.input-search {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	&--loupe {
		position: absolute;
		left: 19px;
		z-index: 100;
		width: 40px;
		height: 40px;
	}

	&--input {
		border-radius: 12px;
		display: flex;
		height: 72px;
		width: 100%;
		padding: 0.75rem 13.75rem 0.75rem 4.5rem;
		align-items: center;
		gap: 0.625rem;
		backdrop-filter: blur(6px);
		outline: none;
		transition: 0.3s;
		font-size: 1.375rem;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: -0.01375rem;
		background-color: white;
		color: var(--grayscale-90, #2D2D2D);
		border: none;

		&:focus ~ .input-search--placeholder {
			display: none;
		}

		&:focus ~ .button.input-search--search {
			display: flex !important;
		}
	}

	&--placeholder {
		position: absolute;;
		left: 4.5rem;
		pointer-events: none;

		//color: rgba(153, 221, 221, 0.70);
		color: var(--grayscale-70, #6C6C6C);
		font-size: 22px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: -0.22px;

		span {
			pointer-events: auto;
			cursor: pointer;
			//color: #E6F7F7;
			color: var(--green-90, #00ABAA);
			text-decoration-line: underline;
		}
	}

	&--clean {
		position: absolute;
		right: 142px;
	}

	&--search.button {
		position: absolute;
		z-index: 100;
		width: 110px;
		color: var(--white, #FFF);
		display: none;

		height: 56px;
		right: 8px;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 100%; /* 16px */
		letter-spacing: -0.16px;
	}


	@media (max-width: 992px) {
		&--loupe {
			left: 12px;
			width: 24px;
			height: 24px;
		}

		&--input {
			height: 48px;
			gap: 12px;
			border-radius: 8px;
			font-size: 16px;
			font-weight: 400;
			line-height: 140%; /* 22.4px */
			letter-spacing: -0.08px;
			padding: 6px 89px 6px 42px;
		}

		&--placeholder {
			font-size: 16px;
			letter-spacing: -0.08px;
			left: 42px;
		}

		&--clean {
			right: 62px;
		}

		&--search.button {
			width: 40px;
			height: 40px;
			right: 4px;
		}
	}
}

.input-search_filled {
	&:hover {
		.input-search {
			&--loupe {
				width: 48px;
				height: 48px;
			}
			&--placeholder {
				left: 90px;
			}
		}
	}

	.input-search {


		&--loupe {
			width: 40px;
			height: 40px;
			left: 25px;
			transition: all .2s ease-in-out;
		}

		&--input {
			border-radius: 16px;
			background: rgba(51, 188, 187, 0.60);
			border: 0.125rem solid #99dddd47;
			color: white;
			height: 96px;
			padding: 0.75rem 13.75rem 0.75rem 2.5rem;

			&:focus, &._filled {
				background-color: white;
				color: var(--grayscale-90, #2D2D2D);
				border: none;
			}

			&:focus + .input-search--loupe,
			&._filled + .input-search--loupe {
				display: none !important;
			}

		}

		&--placeholder {
			color: rgba(153, 221, 221, 0.70);
			left: 80px;
			transition: all .2s ease-in-out;

			span {
				color: #E6F7F7;
			}
		}
		&--clean {
			right: 188px;
		}

		&--search.button {
			height: 72px;
			right: 12px;
			width: 148px;
			font-size: 24px;
			font-style: normal;
			font-weight: 400;
			line-height: 100%;
			letter-spacing: -0.015rem;
		}

		@media (max-width: 992px) {
			&--loupe {
				left: 12px;
				width: 24px;
				height: 24px;
			}

			&--input {
				height: 48px;
				gap: 12px;
				border-radius: 8px;
				padding: 6px 89px 6px 42px;

				&:focus, &._filled {

					padding: 6px 89px 6px 18px;
				}
			}

			&--clean {
				right: 62px;
			}

			&--search.button {
				width: 40px;
				height: 40px;
				right: 4px;
			}


			&--placeholder {
				left: 42px;
			}
		}
	}
}

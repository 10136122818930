.input-text {
  width: 100%;
  position: relative;
  display: block;

  label {
    width: 100%;
    display: block;
    color: var(--grayscale-50, #969696);
    margin-bottom: 6px;

    span {
      color: #E7417A;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.28px;
    }
  }

  &__wrapper {
    width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 0 10px;
    border-radius: 8px;
    border: 1px solid var(--grayscale-10, #EAEAEA);

    input, textarea {
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      display: block;
      width: 100%;
    }
  }

  &__infix {
    padding: 16px 0;
    flex: 1 1 auto;
    cursor: text;
  }

  &__placeholder {
    position: absolute;
    color: #C0C0C0;
    font-size: 16px;
    line-height: 120%;
  }

  .input-suffix, .input-prefix {
    flex: 0 0 auto;
    color: #C0C0C0;
    display: flex;
    align-items: center;
  }

  &.ng-touched.ng-invalid {
    .input-text__wrapper {
      border-color: #E7417A;
    }
  }

  &--focused:not(&--disabled), &:hover:not(&--disabled) {
    .input-text__wrapper {
      border-color: var(--green-30, #99DDDD);
    }
  }

  &--has-value {
    .input-text__placeholder {
      display: none;
    }
  }

  &--disabled {
    .input-text__wrapper {
      border-color: transparent;
      background: #F6F6F6;
    }
  }

  &--horizontal {
    align-items: flex-start;
    grid-template: ". .";
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 24px;

    .input-text__field {
      flex:  0 0 auto;
      width: 100%;
    }

    .input-label {
      flex:  0 0 auto;
      width: 100%;
      min-width: 30%;
    }
  }

  .input-error {
    margin-top: 6px;
  }
}

.mat-icon {
  stroke: currentColor;

  svg {
    display: block;
  }
}

a {
  &:not(.disabled), &:not(:disabled) {
    cursor: pointer;
  }
}

.backdrop--transparent {
  opacity: 0;
}

.cdk-overlay-container {
  z-index: 3001;
}

.dialog-overlay-container {
  z-index: 3000;

  .cdk-overlay-backdrop {
    background: rgba(#2D2D2D, 0.36);
  }

  .cdk-global-overlay-wrapper {
    overflow-y: auto;
  }

  .cdk-dialog-container {
    --mdc-dialog-container-elevation-shadow: none;
    --mdc-dialog-container-shape: 24px;
  }
}

.modal-drawer {
  .cdk-dialog-container {
    --mdc-dialog-container-shape: 0;
  }
}

body {
  /* total width */
  ::-webkit-scrollbar {
    background-color: #EAEAEA;
    width: 6px;
    height: 6px;
    border-radius: 4px;

    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* background of the scrollbar except button or resizer */
  ::-webkit-scrollbar-track {
    background-color: #EAEAEA;
    border-radius: 4px;
  }

  /* scrollbar itself */
  ::-webkit-scrollbar-thumb {
    background-color: #6C6C6C;
    border-radius: 6px;
    @media screen and (max-width: 899px) {
      width: 0;
    }
  }

  /* set button(top and bottom of the scrollbar) */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: rgba(var(--link-color-rgb), var(--link-opacity, 1));
}

.link {
  color: rgba(var(--link-color-rgb), var(--link-opacity, 1));
  cursor: pointer;

  &:hover {
    --link-color-rgb: var(--link-hover-color-rgb);
  }
}

@import "assets/fonts/helvetica-neue/stylesheet.css";
@import "./styles/index";
@import "./styles/general";


.mat-mdc-menu-panel {
  border-radius: 0.5rem !important;
  border: 1px solid var(--grayscale-10, #EAEAEA) !important;
  background: #FFF !important;
  margin: 24px 0 0 !important;
  box-shadow: none !important;
  width: 240px;
}

.mat-mdc-menu-item {
  min-height: 40px !important;

  button {
    color: var(--grayscale-70, #6C6C6C) !important;
    font-family: "Helvetica Neue", sans-serif !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important;
    letter-spacing: -0.005rem !important;
  }
}

.mat-mdc-menu-item:not([disabled]):hover {
  background-color: var(--green-10, #E6F7F7) !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  $padding: 40px;
  padding: $padding;

  .header {
    color: var(--grayscale-90, #2D2D2D);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.0225rem;
    margin-bottom: 32px;
  }

  .modal-close {
    cursor: pointer;
    position: absolute;
    top: calc(16px - $padding);
    right: calc(16px - $padding);
    color: #6C6C6C;

    &:hover {
      opacity: .7;
    }
  }

  .actions {
    display: flex;
    padding: 32px 0 0;
    gap: 8px;
  }

  // .ng-select .ng-select-container:hover {
  //   box-shadow: none !important;
  // }

  @media (max-width: 576px) {
    $padding: 16px;
    padding: 40px 12px;
    min-height: 100vh !important;
    display: flex !important;
    flex-direction: column;
    border-radius: 0 !important;

    > * {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }

    .modal-container {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    .header {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      letter-spacing: -0.2px;
      padding-right: 20px;
    }

    .modal-close {
      top: calc(16px - $padding);
      right: calc(16px - $padding);

      .mat-icon {
        width: 20px;
        height: 20px;
      }
    }

    .body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    .actions {
      flex-wrap: wrap;
      padding: 16px 0 0;
      margin-top: auto;

      .button {
        width: 100%;
      }
    }
  }
}

.modal-drawer {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 48px 120px 48px 40px;

    .modal-close {
      top: 0;
      right: calc(56px - 120px);
    }

    @media (max-width: 576px) {
      padding: 20px 12px;

      .modal-close {
        right: 0;
      }
    }
  }
}

button {
  border: none;
  background: none;
  outline: none;
  padding: 0;
}

.button {
  border-radius: 0.5rem;
  background: var(--green-90, #00ABAA);
  border: none;
  display: flex;
  padding: 1rem 1.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--white, #FFF);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.01125rem;
  transition: background-color, color, .2s ease-in-out;
  position: relative;
  gap: 10px;

  &:has(.mat-icon) {
    padding: 14px 24px;
  }

  &:hover {
    background: var(--green-70, #33bcbb);
  }

  &:active /*, &:focus*/
  {
    background: var(--green-50, #66cdcc);
  }

  &:disabled, &.disabled {
    background: var(--grayscale-10, #eaeaea);
    color: var(--grayscale-50, #969696);
  }

  &--bordered {
    padding: 16px 24px;
    box-sizing: border-box;
    z-index: 0;
    border-radius: 8px;
    background: var(--green-70, #33BCBB);


    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      background: linear-gradient(180deg, #66CDCC, rgba(102, 205, 204, 0.09));
      z-index: -1;
      inset: 0;

      padding: 1px;
      mask: linear-gradient(180deg, #66CDCC, rgba(102, 205, 204, 0.09)) content-box,
      linear-gradient(180deg, #66CDCC, rgba(102, 205, 204, 0.09));
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &:hover {
      background: var(--green-50, #66CDCC);
    }

    &:active /*, &:focus*/
    {
      background: var(--green-50, #66CDCC);
    }

    &:disabled, &.disabled {
      color: var(--green-30, #9DD);
      background: var(--green-90, #00ABAA);

      &:before {
        background: none;
        border: 1px solid var(--green-70, #33BCBB);
        mask: none;
      }
    }
  }



  &--small {
    padding: 8px 12px !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.07px;
    gap: 8px !important;

    .mat-icon {
      width: 16px;
      height: 16px;
    }
  }

  &--outline {
    border: 1px solid var(--green-70, #33BCBB);
    background: transparent;
    color: var(--green-90, #00ABAA);

    &:hover {
      background: var(--green-70, #33bcbb);
      color: var(--white, #ffffff);
    }

    &:active {
      border: 1px solid var(--green-50, #66cdcc);
      background: var(--green-50, #66cdcc);
      color: var(--white, #ffffff);
    }

    &:disabled, &.disabled {
      border: 1px solid var(--grayscale-10, #eaeaea);
      background: var(--grayscale-10, #eaeaea);
      color: var(--grayscale-50, #969696);
    }

    &.button--secondary {
      border-color: #C0C0C0;
      color: #6C6C6C;

      &:hover {
        background: #6C6C6C;
        color: white;
      }

      &:active {
        background: #6C6C6C;
        color: white;
      }

      &:disabled, &.disabled {
        border: 1px solid var(--grayscale-10, #eaeaea);
        background: var(--grayscale-10, #eaeaea);
        color: var(--grayscale-50, #969696);
      }
    }

    &.button--white {
      border-color: #66CDCC;
      color: var(--green-30, #9DD);

      &:hover {
        background: transparent;
        color: white;
      }

      &:active {
        background: transparent;
        color: white;
      }

      &:disabled, &.disabled {
        border: 1px solid var(--grayscale-10, #eaeaea);
        background: var(--grayscale-10, #eaeaea);
        color: var(--grayscale-50, #969696);
      }
    }
  }

  &--link {
    border: 1px solid transparent;
    background: transparent;
    color: var(--green-90, #00ABAA);

    &:hover {
      background: var(--green-70, #33bcbb);
      color: var(--white, #ffffff);
    }

    &:active {
      border: 1px solid var(--green-50, #66cdcc);
      background: var(--green-50, #66cdcc);
      color: var(--white, #ffffff);
    }

    &:disabled, &.disabled {
      border: 1px solid var(--grayscale-10, #eaeaea);
      background: var(--grayscale-10, #eaeaea);
      color: var(--grayscale-50, #969696);
    }
  }

  &--flat {
    &.button--secondary {
      background: #6C6C6C;
      color: white;

      &:hover {
        background: #969696;
      }

      &:disabled, &.disabled {
        background: var(--grayscale-10, #eaeaea);
        color: var(--grayscale-50, #969696);
      }
    }
  }
}

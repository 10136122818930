label {
	color: var(--grayscale-5, #F6F6F6);
	font-family: "Helvetica Neue", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 19.2px */
	letter-spacing: -0.16px;
}

.input-wrapper {
	display: flex;
	width: 100%;
	border-radius: 8px;
	position: relative;
	box-sizing: border-box;
	z-index: 1;

	/*input:focus ~ {
	  &:before {
		background: linear-gradient(180deg, #AAF1F0, rgba(197, 255, 254, 0.09));
		mask: linear-gradient(180deg, #AAF1F0, rgba(197, 255, 254, 0.09)) content-box,
		linear-gradient(180deg, #AAF1F0, rgba(197, 255, 254, 0.09));
	  }
	}*/

	input, textarea {
		width: 100%;
		border-radius: 8px;
		background: #FFF;
		padding: 15px 12px;
		font-family: "Helvetica Neue", sans-serif;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%;
		letter-spacing: -0.16px;
		outline: none;
		resize: none;
		background: linear-gradient(#fff 0 0) padding-box,
					linear-gradient(180deg, #AAF1F0, rgba(197, 255, 254, 0.09)) border-box;
		border: 2px solid transparent;
		display: inline-block;
	}


}

input, textarea {
	border-radius: 8px;
	background: #FFF;
	padding: 15px 12px;
	font-family: "Helvetica Neue", sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 19.2px */
	letter-spacing: -0.16px;
	border: none;
	outline: none;
	resize: none;

	&::placeholder {
		color: var(--grayscale-30, #C0C0C0);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 19.2px */
		letter-spacing: -0.16px;
	}
}

form {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.input-group {
		display: flex;
		flex-direction: column;
		gap: 5px;

	}
}

@media (max-width: 768px) {
	label {
		font-size: 14px;
		line-height: 130%; /* 18.2px */
		letter-spacing: -0.28px;
	}
}



.radio-group {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

input[type=radio] {
	display: none;
}

input[type=radio] + label {
	display: inline-block;
	cursor: pointer;
	position: relative;
	padding-left: 30px;
	margin-right: 0;
	user-select: none;

	color: var(--grayscale-90, #2D2D2D);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%; /* 19.2px */
	letter-spacing: -0.08px;
}

input[type=radio] + label:before {
	content: "";
	display: inline-block;
	width: 20px;
	height: 20px;
	position: absolute;
	left: 0;
	bottom: 1px;
	background: url('../../../assets/images/icons/radio-white.svg') 0 0 no-repeat;
}

/* Checked */
input[type=radio]:checked + label:before {
	background: url('../../../assets/images/icons/radio-white_active.svg') 0 0 no-repeat;
}

/* Hover */
input[type=radio] + label:hover:before {
	filter: brightness(80%);
}

/* Disabled */
input[type=radio]:disabled + label:before {
	filter: grayscale(100%);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-family-base;
}

a {
  text-decoration: none;
}


.wide-section {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;

  @media (max-width: 992px) {
    padding-left: 12px;
    padding-right: 12px;
    .container {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
  }
}

.sidenav-opened {
  height: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;

  .header {
    z-index: 1000;
  }
}

.section {
  margin: 60px 0;

  &__title {
    color: var(--grayscale-90, #2D2D2D);
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 61.6px */
    letter-spacing: -0.84px;
    margin-bottom: 56px;

    span {
      color: var(--green-90, #00ABAA);
    }
  }

  &__desc {
    color: var(--grayscale-70, #6C6C6C);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.08px;
    margin-bottom: 16px;
  }

  @media (max-width: 992px) {
    margin: 40px 0;

    &__title {
      font-size: 24px;
      line-height: 120%; /* 28.8px */
      letter-spacing: -0.36px;
      margin-bottom: 24px;
    }

    &__desc {

    }
  }
}

.sidenav--social .social-links{
  a {
    background:  var(--grayscale-5, #F6F6F6) !important;
  }
}

/* todo delete */
.btn-primary {
  --ls-btn-color: white !important;
  --ls-btn-hover-color: white !important;
  --ls-btn-active-color: white !important;
}

.btn-outline-primary {
  --ls-btn-hover-color: white !important;
  --ls-btn-active-color: white !important;
}

.container {
  @include make-container(5rem);
}

.container {
  @media (max-width: 991px) {
    padding-right: calc(1.5rem * .5) !important;
    padding-left: calc(1.5rem * .5) !important;
  }
}

.main-block {
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    border-radius: 50%;
    opacity: 0.6;
    background: #003939;
    mix-blend-mode: overlay;
    filter: blur(200px);
    width: 1200px;
    height: 1200px;
    display: block;
    position: absolute;
    left: 50%;
    top: 43%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 0;
  }

  &._page-search {
    .container {
      position: relative;
    }
  }
}
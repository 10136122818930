/* Select container */
.ng-select.ng-select-multiple .ng-select-container,
.ng-select.ng-select-single .ng-select-container {
  border-radius: 8px;
  border: 1px solid var(--white, #FFF);
  background: #00ABAA;
  min-height: 40px;
  padding: 3px 0;
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container,
.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-radius: 8px;
}

/* Arrow */
.ng-select {
  .ng-arrow-wrapper {
    width: 24px;
    padding-right: 0;
    margin-right: 12px;
    display: flex;
    align-items: center;
  }


  .ng-arrow {
    border: none !important;
    background-image: url(/assets/images/arrow-select-white.svg);
    background-size: 100%;
    width: 24px !important;
    height: 24px !important;
    transform: rotate(0);
    transition: transform .1s ease-in-out;
  }

  &.ng-select-opened .ng-arrow {
    top: 0 !important;
    transform: rotate(180deg);
  }
}


/* Value & input */
.ng-select.ng-select-single .ng-select-container .ng-value-container {
  .ng-value {
    white-space: nowrap;
    display: flex !important;
    flex-direction: row-reverse !important;
    border-radius: 0.25rem;
    background: transparent;
    color: var(--white, #FFF);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.005rem;
  }

  .ng-input {
    top: 9px;

    > input {
      color: #fff;
      outline: none;
    }
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding: 0;
  gap: 4px;
  margin-left: 4px;
  flex-wrap: nowrap !important;

  .ng-value {
    white-space: nowrap;
    display: flex !important;
    border-radius: 0.25rem;
    background: var(--green-70, #33BCBB);
    color: var(--white, #FFF);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.005rem;
    height: 32px;
    align-items: center;
    gap: 10px;
    padding-left: 12px;
    padding-right: 12px;
    margin: 0;

    .ng-value-label {
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: 8px;
      height: 8px;
      cursor: pointer;
    }
  }

  .ng-input {
    display: none;
  }
}

.ng-dropdown-panel {
  .ng-dropdown-panel-items, .ng-dropdown-header {
    .ng-option {
      &.ng-option-marked, &.ng-option-selected.ng-option-marked {
        background: none;
        color: unset;
      }
    }
  }
}

/* Dropdown */
.ng-dropdown-panel {

  &.ng-select-bottom,
  &.ng-select-top {
    margin: 3px 0;
    border: 1px solid var(--grayscale-10, #EAEAEA);
    background: var(--white, #FFF);
    border-radius: 8px;
    overflow: hidden;
  }

  .ng-dropdown-header {
    padding: 0;

    .ng-option {
      cursor: pointer;
    }
  }

  .ng-dropdown-panel-items, .ng-dropdown-header {
    color: var(--grayscale-70, #6C6C6C);
    font-family: "Helvetica Neue", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.005rem;

    .ng-option.ng-option-selected {
      background: #fff;

      .ng-option-label {
        font-weight: normal !important;
      }
    }

    .ng-option {
      position: relative;
      display: flex !important;
      align-items: center;
      gap: 10px;
      padding: 9px 16px;
      color: var(--grayscale-70, #6C6C6C);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 22.4px */
      letter-spacing: -0.08px;

      &:hover {
        background: #E6F7F7 !important;
        color: var(--green-90, #00ABAA);
      }
    }
  }
}

.ng-select.ng-select-multiple .ng-dropdown-panel {
  .ng-dropdown-panel-items, .ng-dropdown-header {
    .ng-option {
      &:before {
        content: '';
        width: 24px;
        height: 24px;
        display: flex;
        background-image: url("/assets/images/icons/select/checkbox.svg");
        flex: 0 0 auto;
      }

      &:hover {
        &:before {
          background-image: url("/assets/images/icons/select/checkbox-hover.svg");
        }
      }

      &.ng-option-selected {
        &:before {
          background-image: url("/assets/images/icons/select/checkbox-checked.svg");
        }
      }

      input {
        position: absolute;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}


/* todo delete :) */
.white-select {
  .ng-select.ng-select-disabled .ng-select-container {
    background: var(--grayscale-5, #F6F6F6) !important;
    border: 1px solid var(--grayscale-10, #F6F6F6) !important;
  }

  .ng-select.ng-select-multiple .ng-select-container,
  .ng-select.ng-select-single .ng-select-container {
    border-radius: 8px;
    background: #FFF;
    min-height: 52px;
    padding: 3px 0;
    border: 1px solid var(--grayscale-10, #EAEAEA);
  }

  .ng-select-container .ng-value-container {
    .ng-placeholder {
      color: #C0C0C0;
    }
  }

  &.placeholder-as-option .ng-placeholder {
    color: var(--grayscale-90, #2D2D2D) !important;
  }


  .ng-placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.08px;
    padding-left: 0;
  }

  .ng-select {
    .ng-arrow-wrapper {
      margin-right: 16px;
      flex: 0 0 auto;
    }

    .ng-arrow {
      background-image: url(/assets/images/arrow-select.svg);
    }

    .ng-select-container .ng-value-container {
      align-items: center;
      padding-left: 16px;
      flex: 1 1 auto;
      min-width: 0;
    }

    &.ng-select-single .ng-select-container .ng-value-container {
      .ng-value {
        color: var(--grayscale-90, #2D2D2D);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */
        letter-spacing: -0.08px;
      }
    }
  }
}
